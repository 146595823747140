import { useEffect } from "react"

export default () => {
  useEffect(() =>
    window.location.replace(
      "https://tickets.goudenkoets.nl/ZmJsNP72/productDetail/28236"
    )
  )
  return "redirecting..."
}
